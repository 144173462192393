import { useNavigate } from '@solidjs/router';
import { createMemo, For, onMount, Show } from 'solid-js';
import {
  PROPERTY_FOR_YOU_TITLE,
  PROPERTY_FOR_YOU_SUBTITLE,
  VIEW_ALL,
  SUBMIT_A_RENTAL_APPLICATION,
  WE_CURRENTLY_DO_NOT_HAVE_ANY_AVAILABLE_UNITS,
} from '~/assets/strings';
import { Button } from '~/components/common/buttons';
import { PropertyItem } from '~/components/property-item/PropertyItem';
import { PropertyItemSkeleton } from '~/components/property-item/PropertyItemSkeleton';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useStyling } from '~/global-contexts/CompanyStyling';
import { useLocalization } from '~/hooks/useLocalization';
import { SearchTab } from '~/pages/home/SearchTab';
import { NothingHere } from '~/pages/properties/NothingHere';
import { CompanyUnitListingPresenter } from '~/presenters/CompanyUnitListingPresenter';
import { UnitParams } from '~/types/Unit';
import { GetCompanyUnitsListingUseCase } from '~/use-cases/company-unit-listing/getCompanyListingUseCase';
import { CreateRentalApplicationUseCase } from '~/use-cases/rental-applications/createRentalApplicationUseCase';

export const unitParams: UnitParams = {
  page: 1,
  pageSize: 6,
};

export const PropertyForYou = () => {
  const navigate = useNavigate();
  const { t } = useLocalization();

  const { execute: createRentalApplication } = useUseCase(CreateRentalApplicationUseCase);
  const { execute: getCompanyUnitListing, isLoading } = useUseCase(GetCompanyUnitsListingUseCase);
  const { model: unitListing } = usePresenter(CompanyUnitListingPresenter);
  const { companyStyling } = useStyling();

  const onCreateRentalApplication = async () => {
    await createRentalApplication();
  };

  onMount(() => {
    getCompanyUnitListing(unitParams);
  });

  const FallbackWhenListingIsEmpty = createMemo(() => {
    if (companyStyling?.()?.canReceiveRentalApplications) {
      return (
        <div class="mx-auto mt-[45px] flex w-full max-w-container flex-col items-center justify-center gap-6 px-4 md:mt-[60px]">
          <div class="text-center text-base text-[#93A7BD] sm:text-lg md:w-[530px] md:text-[18px]">
            {t(WE_CURRENTLY_DO_NOT_HAVE_ANY_AVAILABLE_UNITS)}
          </div>

          <Button id="home-submit-rental-application" onClick={onCreateRentalApplication} class="w-full justify-center sm:w-auto">
            {t(SUBMIT_A_RENTAL_APPLICATION)}
          </Button>
        </div>
      );
    }

    return <NothingHere />;
  });

  return (
    <div class="relative flex w-full flex-col items-center">
      <div class="relative w-full">
        <div
          class="pb-18 absolute left-1/2 top-[-4px] w-full max-w-[90%] -translate-x-1/2 -translate-y-1/2 sm:max-w-[600px]
              sm:pb-0 md:max-w-[800px] lg:max-w-[1152px]">
          <SearchTab />
        </div>
      </div>

      <div class="mx-auto mt-[220px] flex w-full max-w-container flex-col items-center px-4 md:mt-[100px]">
        <Show
          when={!isLoading()}
          fallback={
            <div class="mt-8 grid w-full grid-cols-1 gap-4 sm:mt-10 sm:grid-cols-2 sm:gap-4 md:mt-[57px] md:grid-cols-3 md:gap-6">
              <For each={Array(3)}>{() => <PropertyItemSkeleton />}</For>
            </div>
          }>
          <Show when={unitListing()?.items.length} fallback={<FallbackWhenListingIsEmpty />}>
            <div class="text-center text-2xl font-semibold text-navy sm:text-3xl md:text-[42px]">{t(PROPERTY_FOR_YOU_TITLE)}</div>

            <div class="mt-3 text-center text-base text-[#93A7BD] sm:mt-4 sm:text-lg md:mt-5 md:w-[530px] md:text-[18px]">
              {t(PROPERTY_FOR_YOU_SUBTITLE)}
            </div>

            <div class="mt-8 grid w-full grid-cols-1 gap-4 sm:mt-10 sm:grid-cols-2 sm:gap-4 md:mt-[57px] md:grid-cols-3 md:gap-6">
              <For each={unitListing()?.items}>{(item) => <PropertyItem data={item} />}</For>
            </div>

            <div class="mt-6 flex w-full justify-center sm:mt-8 md:mt-10">
              <Button
                id="home-view-all-button"
                class="w-full bg-white text-primary-color sm:w-auto"
                onClick={() => navigate('/properties')}>
                {t(VIEW_ALL)} ⟩
              </Button>
            </div>
          </Show>
        </Show>
      </div>
    </div>
  );
};
